import React from "react"
import { patientRegister } from "../services/parse-bridge"
import { useForm } from "react-hook-form"
import SEO from "../components/seo"
import {
  CellHomeBusiness,
  Checkbox,
  CheckLabel,
  Container,
  DateInput,
  EmailWhatsAppWrap,
  Emegency,
  EmegencyInput,
  Form,
  FormCheckbox,
  FormInput,
  FormInputWrap,
  FormInputWrapBusiness,
  FormLabel,
  FormLabelPK,
  Header,
  HeaderRow,
  HeaderVisit,
  Input,
  InputWrap,
  InputWrapDate,
  Label,
  MailingAddress,
  MailLabel,
  NameWrap,
  PreferredLabel,
  PreferredWrap,
  PreffWrap,
  SexRow,
  SignatureInput,
  SignatureTitle,
  SignatureWrap,
  SupposeWrap,
  Text,
  Title,
  TitleVisit,
  VisitForm,
  VisitFormBox,
  VisitFormBoxBdr,
  VisitWrap,
  WomenWrap,
  Wrapper,
} from "../components/admin/patient-form-components"
import MainButton from "../components/main-button"
import toast from "react-hot-toast"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import { SectionSubtitle } from "../components/Section-Subtitle"
import InsuranceLogos from "../components/insurance-logos"

const PatientRegistration = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = async data => {
    const savingToast = toast.loading("Saving...")
    const registered = await patientRegister(data)
    toast.dismiss(savingToast)
    if (registered.success) {
      toast.success(
        "Thank you! Your information was saved successfully. We're taking you home..."
      )
      setTimeout(() => {
        navigate("/")
      }, 3000)
    } else {
      console.log(registered, "error")
      toast.error(
        "Uh oh, something went wrong saving your form. Please try again."
      )
    }
  }

  return (
    <Layout noNav>
      <Container>
        <SEO title="Patient Registration" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Wrapper>
            <Header>
              <Title>
                Smile Inn Patient Registration and Health History Form
              </Title>
              <HeaderRow>
                <NameWrap>
                  <InputWrap>
                    <Label>Name:</Label>
                    <Input
                      autoFocus
                      type="text"
                      placeholder="Jane Doe"
                      {...register("name", { required: true })}
                    />
                  </InputWrap>
                </NameWrap>
                <div style={{ display: "flex" }}>
                  <InputWrapDate>
                    <Label style={{ display: "flex", alignSelf: "center" }}>
                      Date of Birth:
                    </Label>
                    <DateInput
                      type="date"
                      {...register("dateOfBirth", { required: true })}
                    />
                  </InputWrapDate>
                </div>
                <SexRow>
                  <InputWrap>
                    <CheckLabel>Sex:</CheckLabel>
                    <Checkbox
                      type="radio"
                      value="male"
                      {...register("gender")}
                    />
                    <Label>Male</Label>
                    <Checkbox
                      type="radio"
                      value="female"
                      {...register("gender", { required: true })}
                    />
                    <Label>Female</Label>
                  </InputWrap>
                </SexRow>
              </HeaderRow>
            </Header>
            <Form>
              {/* FIRST SECTION OF THE FORM. CONTACT INFORMATION */}
              <MailingAddress>
                <FormInputWrap>
                  <MailLabel>Mailing Address:</MailLabel>
                  <FormInput
                    type="text"
                    {...register("mailAddress", { required: true })}
                  />
                </FormInputWrap>
              </MailingAddress>

              <EmailWhatsAppWrap>
                <FormInputWrap className="emailCell">
                  <FormLabel>Email:</FormLabel>
                  <FormInput
                    type="text"
                    {...register("email", { required: true })}
                  />
                </FormInputWrap>
                <FormInputWrap className="emailCell">
                  <FormLabel>Cell:</FormLabel>
                  <FormInput
                    type="text"
                    {...register("phoneNumber", { required: true })}
                  />
                </FormInputWrap>
                <FormInputWrap>
                  <FormLabel>WhatsApp (if different):</FormLabel>
                  <FormInput type="text" {...register("whatsapp")} />
                </FormInputWrap>
              </EmailWhatsAppWrap>

              <Emegency>
                <FormInputWrap>
                  <FormLabel>Emergency Contact Name:</FormLabel>
                  <EmegencyInput
                    type="text"
                    {...register("emergencyContactName", { required: true })}
                  />
                </FormInputWrap>
                <SupposeWrap>
                  <InputWrap>
                    <FormCheckbox
                      type="radio"
                      value="spouse"
                      {...register("emergencyContactRelation")}
                    />
                    <FormLabel style={{ marginRight: "15px" }}>
                      Spouse
                    </FormLabel>
                    <FormCheckbox
                      type="radio"
                      value="parent"
                      {...register("emergencyContactRelation")}
                    />
                    <FormLabel style={{ marginRight: "15px" }}>
                      Parent
                    </FormLabel>
                    <FormCheckbox
                      type="radio"
                      value="family"
                      {...register("emergencyContactRelation")}
                    />
                    <FormLabel style={{ marginRight: "15px" }}>
                      Family
                    </FormLabel>
                    <FormCheckbox
                      type="radio"
                      value="friend"
                      {...register("emergencyContactRelation")}
                    />
                    <FormLabel style={{ marginRight: "15px" }}>
                      Friend
                    </FormLabel>
                  </InputWrap>
                </SupposeWrap>
              </Emegency>
              {/* CONTACT INFORMATION */}
              <CellHomeBusiness>
                <FormInputWrapBusiness>
                  <FormLabel>Cell</FormLabel>
                  <FormInput type="text" {...register("cellContact")} />
                </FormInputWrapBusiness>
                <FormInputWrapBusiness>
                  <FormLabel>Home:</FormLabel>
                  <FormInput type="text" {...register("homeContact")} />
                </FormInputWrapBusiness>
                <FormInputWrapBusiness>
                  <FormLabel>Business</FormLabel>
                  <FormInput type="text" {...register("businessContact")} />
                </FormInputWrapBusiness>
              </CellHomeBusiness>
              {/* PREFERRED CONTACT METHODS */}
              <PreferredWrap>
                <PreffWrap>
                  <PreferredLabel>
                    Preferred contact for appointments:
                  </PreferredLabel>
                  <InputWrap>
                    <FormCheckbox
                      type="radio"
                      value="email"
                      {...register("preferredContactMethodForAppointments")}
                    />
                    <FormLabelPK style={{ marginLeft: "10px" }}>
                      Email
                    </FormLabelPK>
                  </InputWrap>
                  <InputWrap>
                    <FormCheckbox
                      type="radio"
                      value="whatsapp"
                      {...register("preferredContactMethodForAppointments")}
                    />
                    <FormLabelPK style={{ marginLeft: "10px" }}>
                      WhatsApp
                    </FormLabelPK>
                  </InputWrap>
                  <InputWrap>
                    <FormCheckbox
                      type="radio"
                      value="phone"
                      {...register("preferredContactMethodForAppointments")}
                    />
                    <FormLabelPK style={{ marginLeft: "10px" }}>
                      Phone call
                    </FormLabelPK>
                  </InputWrap>
                </PreffWrap>
              </PreferredWrap>
              <PreferredWrap>
                <PreffWrap>
                  <PreferredLabel>Preferred contact for offers:</PreferredLabel>
                  <InputWrap>
                    <FormCheckbox
                      type="radio"
                      value="email"
                      {...register("preferredContactMethodForOffers")}
                    />
                    <FormLabelPK style={{ marginLeft: "10px" }}>
                      Email
                    </FormLabelPK>
                  </InputWrap>
                  <InputWrap>
                    <FormCheckbox
                      type="radio"
                      value="whatsapp"
                      {...register("preferredContactMethodForOffers")}
                    />
                    <FormLabelPK style={{ marginLeft: "10px" }}>
                      WhatsApp
                    </FormLabelPK>
                  </InputWrap>
                  <InputWrap>
                    <FormCheckbox
                      type="radio"
                      value="phone"
                      {...register("preferredContactMethodForOffers")}
                    />
                    <FormLabelPK style={{ marginLeft: "10px" }}>
                      Phone call
                    </FormLabelPK>
                  </InputWrap>
                </PreffWrap>
              </PreferredWrap>
            </Form>
            {/* </form> */}
          </Wrapper>

          {/* INSURANCE INQUIRY */}
          <Wrapper>
            <HeaderVisit>
              <VisitWrap>
                <TitleVisit style={{ marginRight: "10px" }}>
                  DENTAL INSURANCE:
                </TitleVisit>
              </VisitWrap>
            </HeaderVisit>
            <VisitFormBox
              style={{ borderBottom: "2px solid black", width: "100%" }}
            >
              <SectionSubtitle style={{ marginTop: "30px" }}>
                We accept these Insurance Plans and more
              </SectionSubtitle>
              <InsuranceLogos />
              <p>Let us know your Insurance Provider (optional):</p>
              <FormInputWrap className="emailCell">
                <FormInput
                  type="text"
                  placeholder="eg. Beacon Insurance"
                  {...register("insuranceProvider", { required: false })}
                />
              </FormInputWrap>
            </VisitFormBox>
          </Wrapper>

          {/* REASON FOR VISITING */}
          <Wrapper>
            <HeaderVisit>
              <VisitWrap>
                <TitleVisit style={{ marginRight: "10px" }}>
                  REASON FOR VISIT:
                </TitleVisit>
                <Text>What is the reason for your visit today?</Text>
              </VisitWrap>
            </HeaderVisit>
            <VisitForm>
              <VisitFormBoxBdr>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("gumSensitivity")}
                  />
                  <FormLabel>Dental pain/ Gum sensitivity</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("isCheckUp")} />
                  <FormLabel>Check Up</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("isClean")} />
                  <FormLabel>Clean </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("isChildExam")} />
                  <FormLabel>Child Exam </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("isBrokenTooth")}
                  />
                  <FormLabel>Broken Tooth </FormLabel>
                </InputWrap>
              </VisitFormBoxBdr>
              <VisitFormBox>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("isGeneralConsult")}
                  />
                  <FormLabel>
                    General/Invisalign/Tooth whitening Consultation
                  </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("isDigitalSmile")}
                  />
                  <FormLabel>Digital Smile Design Consultation</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("isBonding")} />
                  <FormLabel>Bonding</FormLabel>
                </InputWrap>
              </VisitFormBox>
            </VisitForm>
          </Wrapper>

          {/* PAST DENTAL HISTORY */}
          <Wrapper>
            <HeaderVisit>
              <VisitWrap>
                <TitleVisit style={{ marginRight: "10px" }}>
                  PAST DENTAL HISTORY:
                </TitleVisit>
                <Text>Have you previously had any of the following?</Text>
              </VisitWrap>
            </HeaderVisit>
            <VisitForm>
              <VisitFormBoxBdr>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("historyOrthodontic")}
                  />
                  <FormLabel>Orthodontic treatment</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("historyfillings")}
                  />
                  <FormLabel>Fillings</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("historyToothWhitening")}
                  />
                  <FormLabel>Tooth whitening </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("historyWisdomTooth")}
                  />
                  <FormLabel>Wisdom tooth/ extraction </FormLabel>
                </InputWrap>
              </VisitFormBoxBdr>
              <VisitFormBox>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("historyBonding")}
                  />
                  <FormLabel>Bonding</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("historyRootCanalTreatment")}
                  />
                  <FormLabel>Root canal treatment</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("historySedation")}
                  />
                  <FormLabel>Sedation</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("historyCrownBridge")}
                  />
                  <FormLabel>Crown/Bridge</FormLabel>
                </InputWrap>
              </VisitFormBox>
            </VisitForm>
          </Wrapper>

          {/* ALLERGIES */}
          <Wrapper>
            <HeaderVisit>
              <VisitWrap>
                <TitleVisit style={{ marginRight: "10px" }}>
                  ALLERGIES:
                </TitleVisit>
                <Text> Do you have any allergies?</Text>
              </VisitWrap>
            </HeaderVisit>
            <VisitForm>
              <VisitFormBoxBdr>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("allergiesValium")}
                  />
                  <FormLabel>Valium or other tranquilizers </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("allergiesLocal")}
                  />
                  <FormLabel>Local anesthetic (Novocain, adrenalin)</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("allergiesCodeine")}
                  />
                  <FormLabel>Codeine or other narcotics </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("allergiesLatex")}
                  />
                  <FormLabel>Latex </FormLabel>
                </InputWrap>
              </VisitFormBoxBdr>
              <VisitFormBox>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("allergiesPenicillin")}
                  />
                  <FormLabel>Penicillin, Amoxicillin, Augmentin</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("allergiesAspirin")}
                  />
                  <FormLabel>Aspirin, Advil, Motrin, Ibuprofen</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("allergiesSulfa")}
                  />
                  <FormLabel>Sulfa/sulfites </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("allergiesOtherAntibiotics")}
                  />
                  <FormLabel>Other Antibiotics</FormLabel>
                </InputWrap>
              </VisitFormBox>
            </VisitForm>
          </Wrapper>

          <Text style={{ color: "black", textAlign: "left" }}>
            <i>
              Please answer the following questions to the best of your
              knowledge. Although dentists primarily treat the mouth area,
              medical problems or medications could have significant impact on
              your dental treatment.
            </i>
          </Text>

          <Wrapper>
            <HeaderVisit>
              <VisitWrap>
                <TitleVisit>GENERAL:</TitleVisit>
              </VisitWrap>
            </HeaderVisit>

            {/* GENERAL INFORMATION */}
            <VisitFormBox
              style={{ borderBottom: "2px solid black", width: "100%" }}
            >
              <InputWrap style={{ marginBottom: "10px" }}>
                <FormCheckbox
                  type="checkbox"
                  {...register("areYouInGoodHealth")}
                />
                <FormLabel>Are you in good health? </FormLabel>
              </InputWrap>
              <InputWrap style={{ marginBottom: "10px" }}>
                <FormCheckbox
                  type="checkbox"
                  {...register("underPhysicianCare")}
                />
                <FormLabel>
                  Are you under the care of a physician? Date of last physical
                  examination
                </FormLabel>
              </InputWrap>
              <InputWrap style={{ marginBottom: "10px" }}>
                <FormCheckbox
                  type="checkbox"
                  {...register("hasIllnessOrOperations")}
                />
                <FormLabel>
                  Have you had any illness, operation, or been hospitalized in
                  the past five years?{" "}
                </FormLabel>
              </InputWrap>
              <InputWrap style={{ marginBottom: "10px" }}>
                <FormCheckbox type="checkbox" {...register("onAntiBiotics")} />
                <FormLabel>
                  Have you been told by your physician to take antibiotics prior
                  to dental today?{" "}
                </FormLabel>
              </InputWrap>
              <InputWrap style={{ marginBottom: "10px" }}>
                <FormCheckbox
                  type="checkbox"
                  {...register("healthCondition")}
                />
                <FormLabel>
                  Is there any health condition about which the doctor should
                  know?
                </FormLabel>
              </InputWrap>
              <InputWrap style={{ marginBottom: "10px" }}>
                <FormCheckbox
                  type="checkbox"
                  {...register("speakToDoctorPrivately")}
                />
                <FormLabel>
                  Do you wish to speak to the doctor privately about anything?
                </FormLabel>
              </InputWrap>

              <WomenWrap>
                <InputWrap
                  style={{ marginBottom: "10px", marginRight: "15px" }}
                >
                  <FormLabel>
                    <b>WOMEN, </b> are you:
                  </FormLabel>
                </InputWrap>
                <InputWrap
                  style={{ marginBottom: "10px", marginRight: "15px" }}
                >
                  <FormCheckbox
                    type="checkbox"
                    {...register("pregnant")}
                    style={{ marginRight: "5px" }}
                  />
                  <FormLabel>Pregnant</FormLabel>
                </InputWrap>
                <InputWrap
                  style={{ marginBottom: "10px", marginRight: "15px" }}
                >
                  <FormCheckbox
                    type="checkbox"
                    {...register("possibly")}
                    style={{ marginRight: "5px" }}
                  />
                  <FormLabel>Possibly</FormLabel>
                </InputWrap>
                <InputWrap
                  style={{ marginBottom: "10px", marginRight: "15px" }}
                >
                  <FormCheckbox
                    type="checkbox"
                    {...register("nursing")}
                    style={{ marginRight: "5px" }}
                  />
                  <FormLabel>Nursing</FormLabel>
                </InputWrap>
                <InputWrap
                  style={{ marginBottom: "10px", marginRight: "15px" }}
                >
                  <FormCheckbox
                    type="checkbox"
                    {...register("takingBirthControl")}
                    style={{ marginRight: "5px" }}
                  />
                  <FormLabel> Taking Birth Control Pills</FormLabel>
                </InputWrap>
              </WomenWrap>
            </VisitFormBox>

            <VisitForm style={{ borderBottom: "1px solid black" }}>
              {/* Genitourinary */}
              <VisitFormBoxBdr>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <TitleVisit
                    style={{
                      color: "black",
                      fontStyle: "italic",
                      borderBottom: "1px solid black",
                    }}
                  >
                    Genitourinary:
                  </TitleVisit>
                </div>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("bloodInUrine")} />
                  <FormLabel>Blood in urine </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("kidneyTrouble")}
                  />
                  <FormLabel>Kidney trouble</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("prostateCancer")}
                  />
                  <FormLabel>Prostate cancer</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("recurrenturinarytractinfections")}
                  />
                  <FormLabel>Recurrent urinary tract infections </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("uterineCervicalCancer")}
                  />
                  <FormLabel>Uterine/cervical cancer </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("onDialysis")} />
                  <FormLabel>You are on dialysis </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("seizure")} />
                  <FormLabel>Seizure </FormLabel>
                </InputWrap>
              </VisitFormBoxBdr>

              {/* NEUROLOGICAL */}
              <VisitFormBox>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <TitleVisit
                    style={{
                      color: "black",
                      fontStyle: "italic",
                      borderBottom: "1px solid black",
                    }}
                  >
                    Neurological:
                  </TitleVisit>
                </div>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("convulsionsOrEpilepsy")}
                  />
                  <FormLabel>Convulsions/epilepsy</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("difficultyWithSpeech")}
                  />
                  <FormLabel>Difficulty with speech</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("faintingSpells")}
                  />
                  <FormLabel>Fainting spells </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("frequentHeadachesOrMigraines")}
                  />
                  <FormLabel>Frequent headaches/migraines</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("riskOfStroke")} />
                  <FormLabel>Stroke/Transient Ischemic Attack</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("chronicFatigue")}
                  />
                  <FormLabel>Chronic fatigue</FormLabel>
                </InputWrap>
              </VisitFormBox>
            </VisitForm>

            {/* Hematologic */}
            <VisitForm style={{ borderBottom: "1px solid black" }}>
              <VisitFormBoxBdr>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <TitleVisit
                    style={{
                      color: "black",
                      fontStyle: "italic",
                      borderBottom: "1px solid black",
                    }}
                  >
                    Hematologic/Lymphatic
                  </TitleVisit>
                </div>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("anemia")} />
                  <FormLabel>Anemia </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("bloodTransfusions")}
                  />
                  <FormLabel>Blood transfusions</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("hemophilia")} />
                  <FormLabel>Hemophilia/easy bleeding </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("persistentSwollenGlands")}
                  />
                  <FormLabel>Persistent swollen glands/lymph nodes </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("abnormalBleeding")}
                  />
                  <FormLabel>Abnormal bleeding </FormLabel>
                </InputWrap>
              </VisitFormBoxBdr>
              <VisitFormBox>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <TitleVisit
                    style={{
                      color: "black",
                      fontStyle: "italic",
                      borderBottom: "1px solid black",
                    }}
                  >
                    Endocrine:
                  </TitleVisit>
                </div>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("diabetes")} />
                  <FormLabel>Diabetes</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("hormoneProblems")}
                  />
                  <FormLabel>Hormone problems</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("thyroidProblems")}
                  />
                  <FormLabel>Thyroid problems </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("pregnantOrBreastFeeding")}
                  />
                  <FormLabel>You pregnant or breastfeeding</FormLabel>
                </InputWrap>
              </VisitFormBox>
            </VisitForm>

            {/* Cardiovascular */}
            <VisitForm style={{ borderBottom: "1px solid black" }}>
              <VisitFormBoxBdr>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <TitleVisit
                    style={{
                      color: "black",
                      fontStyle: "italic",
                      borderBottom: "1px solid black",
                    }}
                  >
                    Cardiovascular
                  </TitleVisit>
                </div>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("chestPain")} />
                  <FormLabel>Chest pain/angina </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("damagedHeartValve")}
                  />
                  <FormLabel>Damaged heart valve</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("prostheticValve")}
                  />
                  <FormLabel>Prosthetic valve </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("heartAttacks")} />
                  <FormLabel>
                    Heart attack(s)/myocardial infarction (MI){" "}
                  </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("abnormalHeartAnatomy")}
                  />
                  <FormLabel>Abnormal heart anatomy </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("rheumaticHeartDiseaseFever")}
                  />
                  <FormLabel>Rheumatic Heart Disease/ Fever </FormLabel>
                </InputWrap>
              </VisitFormBoxBdr>
              <VisitFormBox>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <TitleVisit
                    style={{
                      color: "black",
                      fontStyle: "italic",
                      borderBottom: "1px solid black",
                    }}
                  >
                    Cardiovascular Continued:
                  </TitleVisit>
                </div>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("heartBypassSurgery")}
                  />
                  <FormLabel>Heart surgery/bypass surgery</FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("highBloodPressure")}
                  />
                  <FormLabel>High blood pressure </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("lowBloodPressure")}
                  />
                  <FormLabel>Low blood pressure </FormLabel>
                </InputWrap>
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox
                    type="checkbox"
                    {...register("mitralValveProlapses")}
                  />
                  <FormLabel>Mitral valve prolapses</FormLabel>
                </InputWrap>{" "}
                <InputWrap style={{ marginBottom: "10px" }}>
                  <FormCheckbox type="checkbox" {...register("heartMurmur")} />
                  <FormLabel>Heart murmur</FormLabel>
                </InputWrap>
              </VisitFormBox>
            </VisitForm>

            <VisitFormBox style={{ width: "100%" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <TitleVisit
                  style={{
                    color: "black",
                    fontStyle: "italic",
                    borderBottom: "1px solid black",
                  }}
                >
                  Musculoskeletal
                </TitleVisit>
              </div>

              <WomenWrap>
                <InputWrap
                  style={{ marginBottom: "10px", marginRight: "15px" }}
                >
                  <FormCheckbox
                    type="checkbox"
                    {...register("arthritis")}
                    style={{ marginRight: "15px" }}
                  />
                  <FormLabel>Arthritis</FormLabel>
                </InputWrap>
                <InputWrap
                  style={{ marginBottom: "10px", marginRight: "15px" }}
                >
                  <FormCheckbox
                    type="checkbox"
                    {...register("brokenBones")}
                    style={{ marginRight: "10px" }}
                  />
                  <FormLabel>Broken Bones</FormLabel>
                </InputWrap>
                <InputWrap
                  style={{ marginBottom: "10px", marginRight: "15px" }}
                >
                  <FormCheckbox
                    type="checkbox"
                    {...register("prostheticJointImplant")}
                    style={{ marginRight: "10px" }}
                  />
                  <FormLabel>Prosthetic Joint Implant</FormLabel>
                </InputWrap>
                <InputWrap
                  style={{ marginBottom: "10px", marginRight: "15px" }}
                >
                  <FormCheckbox
                    type="checkbox"
                    {...register("swollenJointDisease")}
                    style={{ marginRight: "10px" }}
                  />
                  <FormLabel> Swollen Joint Disease</FormLabel>
                </InputWrap>
                <InputWrap
                  style={{ marginBottom: "10px", marginRight: "15px" }}
                >
                  <FormCheckbox
                    type="checkbox"
                    {...register("tumorGrowth")}
                    style={{ marginRight: "10px" }}
                  />
                  <FormLabel>Tumor/Growth</FormLabel>
                </InputWrap>
              </WomenWrap>
            </VisitFormBox>
            <SignatureWrap>
              <FormInputWrap>
                <MailLabel style={{ whiteSpace: "nowrap" }}>
                  Patient Signature: X
                </MailLabel>
                <SignatureInput
                  type="text"
                  placeholder="Sign in block letters"
                  style={{ fontWeight: 600, textTransform: "uppercase" }}
                  {...register("signature", { required: true })}
                />
              </FormInputWrap>
              <FormInputWrap>
                <FormLabel style={{ whiteSpace: "nowrap" }}>Date: X</FormLabel>
                <SignatureInput
                  type="text"
                  placeholder=""
                  {...register("signDate", { required: true })}
                />
              </FormInputWrap>
            </SignatureWrap>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <MainButton
                type="submit"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "8px 30px",
                  margin: "10px 20px",
                  width: "100%",
                  cursor: "pointer",
                  border: "none",
                }}
              >
                Submit
              </MainButton>
            </div>
          </Wrapper>

          <Text style={{ color: "black", textAlign: "left" }}>
            I certify that I have read and understand the questions above. I
            acknowledge that my questions, if any, about the inquiries set forth
            above have been answered to my satisfaction. I will not hold my
            dentist, or any other member of his/her staff, responsible for any
            errors or omissions that I have made in the completion of this form.
            I understand that I am responsible for notifying my dentist of any
            medical changes upon each visit.
          </Text>
        </form>
      </Container>
    </Layout>
  )
}

export default PatientRegistration
